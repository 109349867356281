@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Almarai:wght@300;400;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Tajawal:wght@200;300;400;500;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Hanken+Grotesk:ital,wght@0,100..900;1,100..900&family=Manrope:wght@200..800&display=swap');
@import "~keen-slider/keen-slider.min.css";

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: Almarai, sans-serif;
  behavior: smooth;
  list-style: none;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
*::-webkit-scrollbar {
  display: none;
}
body {
  min-height: 100vh;
}

a {
  text-decoration: none;
}

nav {
  width: 100%;
  position: fixed;
  top: 28px;
  left: 0;
  background-color: white;
  border-bottom: 1px solid rgb(0 0 0 / 4%);
  z-index: 1000;
}

.blure {
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
  background-color: rgb(0 0 0 / 32%);
  color: #fff;
}
.overlay {
  position: absolute;
  left: 0;
  top: 0;
}

.overlay__login {
  background-color: rgb(0 0 0 / 32%);
}
.item {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.mbsc-windows.mbsc-textfield-wrapper {
  margin: 0 !important;
}
.mbsc-windows.mbsc-textfield {
  border: 0px solid rgb(209 213 219) !important;
  border-radius: 6px !important;
  max-height: 40px !important;
  padding: 0 0 !important;
}
.custom-shape-divider-bottom-1713022184 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom-1713022184 svg {
  position: relative;
  display: block;
  width: calc(178% + 1.3px);
  height: 72px;
}

.custom-shape-divider-bottom-1713022184 .shape-fill {
  fill: #ffffff;
}

/** For mobile devices **/
@media (max-width: 767px) {
  .custom-shape-divider-bottom-1713022184 svg {
    width: calc(158% + 1.3px);
    height: 44px;
  }
}

.mySwiper {
  /* padding:  !important; */
  will-change: transform;
}

.spinner {
  animation: spin 2.5s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.datePicker {
  width: 100%;
  display: block;
  height: 45px;
}

.react-datepicker__input-container {
  width: 100%;
  height: 100%;
  align-items: center;
  display: flex;
  padding: 0 10px;

  border: 1px solid rgb(209 213 219);
  border-radius: 7px;
}

.react-datepicker__input-container input {
  width: 100%;
  height: 100%;
  align-items: center;
  display: flex;
  border: none;
  outline: none;
}

.css-lxfshk-MuiInputBase-root-MuiOutlinedInput-root {
  height: 45px !important;
}
.slick-slide {
  margin: 0 10px; /* Adjust spacing between slides */
}
.slick-list {
  margin: 0 -10px; /* Offset the added margin */
}

/* Lazy Loading Styles */

.lazy-image {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.lazy-image.loaded {
  opacity: 1;
}

.placeholder {
  height: 200px; /* Adjust height based on your design */
  background: #f2f2f2; /* Placeholder background color */
  display: flex;
  align-items: center;
  justify-content: center;
  color: #aaa;
  font-size: 14px;
  border-radius: 16px; /* Matches the image/container border radius */
}
